import { SVGProps } from 'react'
import styled from 'styled-components'

export type TCaretSvgProps = SVGProps<SVGElement> & {
  active?: boolean
}

export const Svg = styled.svg<TCaretSvgProps>`
  transform-origin: center;
  transform: scaleY(${props => (props.active ? '-1' : '1')});
  transition: transform ease-in 0.2s;
`
