import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled, { css } from 'styled-components'
import { spacing } from '@typeform/ginger/dist/util/spacing'

export const Heading = styled.h3`
  text-transform: uppercase;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
  margin: 0 0 32px;
  font-weight: ${fontWeight.bold};
  color: inherit;
`

export const ItemsWrapper = styled.div<{
  insideAccordion?: boolean
}>`
  & > *:not(:last-child) {
    margin-bottom: ${spacing(2)};
  }

  ${({ insideAccordion }) =>
    insideAccordion &&
    css`
      padding-left: ${spacing(2)};
      padding-top: ${spacing(2)};
    `}
`
