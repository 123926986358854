import React, { memo } from 'react'

type TMapMarkerProps = React.HTMLAttributes<HTMLOrSVGElement>

const MapMarker = (props: TMapMarkerProps) => {
  return (
    <svg
      fill='none'
      height='17'
      viewBox='0 0 12 17'
      width='12'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect height='14' transform='translate(1.47412 1.32788)' width='10' />
      <path
        d='M6.47412 8.32788C7.57698 8.32788 8.47412 7.43045 8.47412 6.32788C8.47412 5.22531 7.57698 4.32788 6.47412 4.32788C5.37126 4.32788 4.47412 5.22531 4.47412 6.32788C4.47412 7.43045 5.37126 8.32788 6.47412 8.32788Z'
        stroke='currentcolor'
      />
      <path
        d='M2.94498 2.75822C3.88798 1.83593 5.14134 1.32788 6.47461 1.32788C7.80788 1.32788 9.06124 1.83593 10.0042 2.75822C11.7624 4.47797 11.9789 7.71563 10.4614 9.69496L6.47461 15.3279L2.48147 9.68643C0.970354 7.71563 1.18714 4.47797 2.94498 2.75822Z'
        stroke='currentcolor'
      />
    </svg>
  )
}

export default memo(MapMarker)
