import styled from 'styled-components'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { VideoAskLogo } from 'components/atoms/common'

export const StyledLink = styled.a`
  color: inherit;
  display: block;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.large};
`

export const Inner = styled.span`
  transition: transform 0.2s ease-in;
  display: block;

  &:hover {
    transform: translateX(8px);
  }
`

export const StyledVideoAskLogo = styled(VideoAskLogo).attrs({ width: 94 })``
