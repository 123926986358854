import { A11yVisuallyHiddenStyle } from 'constants/ptg/design'

import { Palette } from '@typeform/ginger/dist/constants/palettes'
import styled, { css } from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/media-queries'
import GridContainer from 'components/atoms/grid-container'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import {
  fontSize,
  fontWeight,
} from '@typeform/ginger/dist/constants/typography'
import { Headline } from '@typeform/ginger/dist/components/typography'

import TailLinks from './tail-links'
import Column from './column'
import MapMarker from './map-marker'

const COLORS = {
  positive: NeutralColors.Ink,
  negative: NeutralColors.White,
}

const BACKGROUND_COLORS = {
  positive: NeutralColors.White,
  negative: NeutralColors.Ink,
}

type TContainerProps = {
  palette: Palette
}

export const Container = styled.footer<TContainerProps>`
  background: ${props => BACKGROUND_COLORS[props.palette]};
  color: ${props => COLORS[props.palette]};

  ${mediaQueries.md`
    border-top: ${(props: TContainerProps) =>
      props.palette === Palette.Negative ? 'none' : '1px solid #e7e7e7'};
  `};
`

export const FooterHeader = styled(Headline)`
  ${A11yVisuallyHiddenStyle}
`

export const MainSectionGrid = styled(GridContainer)`
  padding: 80px 32px;
`

export const MainSection = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 64px 32px;
`

export const StyledColumn = styled(Column)`
  min-width: 160px;
  flex-grow: 1;
  flex-basis: 0;
`

export const TailColumn = styled(StyledColumn)`
  ${mediaQueries.md`
    display: none;
  `}
`

export const BottomSection = styled(GridContainer)<{
  isCompact: boolean
  palette: Palette
}>`
  /**
   * Grid template areas
   * lc - location
   * cr - copyright
   * ls - language switcher
   * cs - cookies settings
   */
  padding: 40px 32px;
  align-items: center;

  ${({ isCompact, palette }) => {
    if (!isCompact)
      return css`
        border-top: 1px solid
          ${palette === Palette.Positive ? '#e7e7e7' : NeutralColors.Grey800};
        grid-template-areas: 'lc lc cr cr';

        ${mediaQueries.sm`
          grid-template-areas: 'lc lc lc lc cr cr cr cr';
        `}

        ${mediaQueries.md`
          grid-template-areas: 'lc lc lc ls cs cs cs cs cs cs cs cr';
        `}

        ${mediaQueries.lg`
            grid-template-areas: 'lc lc lc ls cs cs cs cs cs cs cs cr';
        `}
      `

    return css`
      justify-items: center;
      grid-template-areas:
        'ls ls ls ls ls ls ls ls'
        'cs cs cs cs cs cs cs cs'
        'lc lc lc lc lc lc lc lc'
        'cr cr cr cr cr cr cr cr';
      text-align: center;
      row-gap: 16px;

      ${mediaQueries.sm`
        justify-items: left;
        grid-template-areas:
          'ls cs cs cs cs cs cs cs'
          'lc lc lc lc lc lc cr cr';
      `}

      ${mediaQueries.md`
        grid-template-areas: 'lc lc lc ls cs cs cs cs cs cs cs cr';
        text-align: left;
      `}
    `
  }}
`

export const WithLove = styled.span`
  grid-area: lc;
  display: inline-flex;
  align-items: center;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xSmall};
  line-height: 1em;

  svg {
    margin-right: 8px;
  }
`

export const Copyright = styled.span<{
  isCompact?: boolean
}>`
  grid-area: cr;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xSmall};
  line-height: 1em;
  justify-self: right;
  white-space: nowrap;

  ${({ isCompact }) =>
    isCompact &&
    css`
      justify-self: center;

      ${mediaQueries.sm`
  justify-self: right;
  `}
    `}
`

export const BottomSectionLanguageSwitcher = styled.div<{
  isCompact?: boolean
}>`
  grid-area: ls;
  ${({ isCompact }) =>
    !isCompact &&
    css`
      display: none;
    `}

  ${mediaQueries.md`
  display: initial;
`}
`

export const BottomSectionTailLinks = styled(TailLinks)<{
  isCompact?: boolean
}>`
  grid-area: cs;
  ${({ isCompact }) =>
    !isCompact &&
    css`
      display: none;
    `}

  ${mediaQueries.md`
    display: flex;
  `}
`

export const StyledMapMarker = styled(MapMarker)`
  flex-shrink: 0;
`
