import {
  fontWeight,
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import mediaQueries from '@typeform/ginger/dist/util/media-queries'
import styled, { css } from 'styled-components'
import React from 'react'
import GingerLink from 'components/atoms/link'
import { spacing } from '@typeform/ginger/dist/util/spacing'

export const Link = styled(GingerLink)<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>`
  display: block;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
  transition: opacity 150ms ease-in;
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  text-align: inherit;

  &:hover {
    color: ${NeutralColors.Grey500};
  }
`

const horizontalStyles = css`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: ${spacing(2.5)};

  & > * {
    margin-bottom: 0;
  }
`
export const LinkWrapper = styled.div<{
  isCompact?: boolean
}>`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: ${spacing(2)};
  }

  ${({ isCompact }) =>
    isCompact
      ? css`
          & > a:last-of-type {
            margin-bottom: 0;
          }

          ${mediaQueries.sm`
            ${horizontalStyles}
            justify-self: right;
          `}

          ${mediaQueries.md`
            justify-self: center;
            padding: ${spacing(0, 2)};
          `}
        `
      : css`
          ${mediaQueries.md`
           ${horizontalStyles}
           padding: ${spacing(0, 2)};
        `}
        `}
`
