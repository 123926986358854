import {
  TFooterColumn,
  TFooterColumnSection,
  TFooterLink,
} from 'models/footer-model.types'

import Accordion from '../accordion'
import SocialIcons from '../social-icons'
import Link from '../link'

import { Heading, ItemsWrapper } from './column.styles'

type TColumnProps = React.HTMLAttributes<HTMLDivElement> &
  TFooterColumn & {
    expandedAccordion?: string
    toggleAccordion?: (params: { id: string; label: string }) => void
    onLinkClick?: (link: TFooterLink) => () => void
  }

const Column = (props: TColumnProps) => {
  const {
    label,
    items = [],
    expandedAccordion,
    toggleAccordion,
    children,
    onLinkClick,
    contentType,
    ...rest
  } = props

  return (
    <div {...rest}>
      {label && <Heading>{label}</Heading>}
      <ItemsWrapper>
        {!!items &&
          items.map(item => {
            switch (item.contentType) {
              case 'topicFooterLink':
                const itemAsLink = item as TFooterLink
                return (
                  <Link
                    key={item.id}
                    href={itemAsLink.link}
                    onClick={onLinkClick?.(itemAsLink)}
                  >
                    {itemAsLink.label}
                  </Link>
                )
              case 'topicFooterColumnSection':
                const itemAsFooterSection = item as TFooterColumnSection
                const isAccordionExpanded =
                  expandedAccordion === itemAsFooterSection.id
                return (
                  <Accordion
                    key={itemAsFooterSection.id}
                    id={itemAsFooterSection.id || ''}
                    label={itemAsFooterSection.label}
                    expandedAccordion={expandedAccordion}
                    toggleAccordion={toggleAccordion}
                  >
                    {
                      <ItemsWrapper insideAccordion>
                        {itemAsFooterSection.items.map(accordionItem => {
                          return (
                            <Link
                              key={accordionItem.id}
                              href={accordionItem.link}
                              aria-hidden={!isAccordionExpanded}
                              tabIndex={isAccordionExpanded ? 0 : -1}
                              onClick={onLinkClick?.(accordionItem)}
                            >
                              {accordionItem.label}
                            </Link>
                          )
                        })}
                      </ItemsWrapper>
                    }
                  </Accordion>
                )
              case 'topicFooterSocials':
                return <SocialIcons key={item.id} onLinkClick={onLinkClick} />
              default:
                return null
            }
          })}
      </ItemsWrapper>
      {children}
    </div>
  )
}

export default Column
