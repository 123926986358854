import { NAMESPACES } from 'constants/locales'

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { TFooterLink } from 'models/footer-model.types'

import { Link, LinkWrapper } from './tail-links.styles'

export type TLinkMouseEvent =
  | React.MouseEvent<HTMLAnchorElement>
  | React.MouseEvent<HTMLButtonElement>
  | undefined

type TTailLinkProps = React.HTMLAttributes<HTMLDivElement> & {
  links: Array<TFooterLink>
  onCookiePolicySettingsClick: (event: TLinkMouseEvent) => void
  onLinkClick: (link: TFooterLink) => () => void
  isCompact?: boolean
}

const TailLinks = (props: TTailLinkProps) => {
  const {
    links = [],
    onCookiePolicySettingsClick,
    onLinkClick,
    ...rest
  } = props
  const { t } = useTranslation(NAMESPACES.COMMON)

  return (
    <LinkWrapper {...rest}>
      <Link as='button' onClick={onCookiePolicySettingsClick}>
        {t('footer.cookie-policy-settings-link.text')}
      </Link>
      {links.map(link => (
        <Link key={link.label} href={link.link} onClick={onLinkClick(link)}>
          {link.label}
        </Link>
      ))}
    </LinkWrapper>
  )
}

export default TailLinks
