import { css } from 'styled-components'

export const MAX_TEMPLATES_TO_DISPLAY_IN_CAROUSEL = 20
export const A11yVisuallyHiddenStyle = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  overflow: hidden;
`
