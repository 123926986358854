import React, { useCallback, useMemo } from 'react'
import { Collapse } from 'components/atoms/common'

import {
  AccordionHeading,
  LabelEnd,
  LabelStart,
  StyledVerticalCaret,
} from './accordion.styles'

type TAccordionProps = React.HTMLAttributes<HTMLDivElement> & {
  label: string
  id: string
  expandedAccordion?: string
  toggleAccordion?: (params: { id: string; label: string }) => void
}

export default function Accordion(props: TAccordionProps) {
  const { label, children, id, expandedAccordion, toggleAccordion, ...rest } =
    props

  const { labelStart, labelEnd } = useMemo(() => {
    if (label.split(' ').length < 2)
      return { labelStart: null, labelEnd: label }

    const labelStart = label.split(' ').slice(0, -1).join(' ')
    const labelEnd = label.split(' ').slice(-1).join(' ')
    return { labelStart, labelEnd }
  }, [label])

  const expanded = expandedAccordion === id

  const onClick = useCallback(() => {
    toggleAccordion?.({ id, label })
  }, [id, label, toggleAccordion])

  return (
    <div {...rest}>
      <AccordionHeading
        onClick={onClick}
        aria-expanded={expanded}
        aria-label={label}
      >
        {/* Rendering the label in 2 parts is a hack to ensure that the chevron is always paired with the final word. */}
        {/* Without this, due to limitations of CSS, we can't avoid a big gap between text and chevron if text wraps. */}
        {/* See https://stackoverflow.com/questions/37406353/make-container-shrink-to-fit-child-elements-as-they-wrap */}
        {!!labelStart && <LabelStart>{labelStart} </LabelStart>}
        <LabelEnd>
          {labelEnd}
          <StyledVerticalCaret expanded={expanded} />
        </LabelEnd>
      </AccordionHeading>
      <Collapse isOpened={expanded}>{children}</Collapse>
    </div>
  )
}
