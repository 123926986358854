import { spacing } from '@typeform/ginger/dist/util/spacing'
import styled from 'styled-components'

export const SocialIconsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: ${spacing(1)};

  & > a > svg {
    height: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
`
