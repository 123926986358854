import React, { useState } from 'react'

import { Inner, StyledLink, StyledVideoAskLogo } from './link.styles'

type TLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  href?: string
}

export default function Link(props: TLinkProps) {
  const { children, ...rest } = props
  const [isHovered, setIsHovered] = useState(false)
  const linkProps = { ...rest }
  const innerProps = { isHovered }

  // This is a bit of a hacky work-around to detect the VideoAsk text and render a VA logo.
  // As VA is being sun-setted soon, it's probably not worth the effort to support custom logo links just for this case.
  const isVideoAsk = children === 'VideoAsk'
  if (isVideoAsk) {
    linkProps['aria-label'] = 'VideoAsk'
  }

  function handleMouseEnter() {
    setIsHovered(true)
  }

  function handleMouseLeave() {
    setIsHovered(false)
  }

  return (
    <StyledLink
      {...linkProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Inner {...innerProps}>
        {isVideoAsk ? <StyledVideoAskLogo /> : children}
      </Inner>
    </StyledLink>
  )
}
