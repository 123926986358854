import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled from 'styled-components'

export type TLink = {
  color?: string
  fontSize?: string
  align?: string
  children: 'string' | React.ReactNode
}
const Link = styled.a<TLink>`
  color: ${({ color }) => (color ? color : NeutralColors.Ink)};
  font: inherit;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  position: relative;
  text-align: ${({ align }) => (align ? align : 'initial')};
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
`

export default Link
