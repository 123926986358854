import { memo } from 'react'

const LinkedIn = props => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.3591 0.199219C1.44507 0.199219 0.704102 0.940188 0.704102 1.85422V21.8605C0.704102 22.7746 1.44507 23.5156 2.3591 23.5156H22.3654C23.2795 23.5156 24.0204 22.7746 24.0204 21.8605V1.85422C24.0204 0.940189 23.2795 0.199219 22.3654 0.199219H2.3591ZM5.93755 7.44014C7.05465 7.44014 7.96023 6.53455 7.96023 5.41744C7.96023 4.30034 7.05465 3.39475 5.93755 3.39475C4.82045 3.39475 3.91486 4.30034 3.91486 5.41744C3.91486 6.53455 4.82045 7.44014 5.93755 7.44014ZM9.81267 8.93496H13.1649V10.4706C13.1649 10.4706 14.0746 8.65129 16.5497 8.65129C18.7576 8.65129 20.5866 9.73895 20.5866 13.0542V20.0453H17.1127V13.9014C17.1127 11.9457 16.0686 11.7306 15.273 11.7306C13.6218 11.7306 13.3391 13.1548 13.3391 14.1565V20.0453H9.81267V8.93496ZM7.70078 8.93498H4.17432V20.0453H7.70078V8.93498Z'
      fill='currentColor'
    />
  </svg>
)

export default memo(LinkedIn)
