import { memo } from 'react'

const YouTube = props => (
  <svg
    width='25'
    height='21'
    viewBox='0 0 25 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.2646 2.27445C23.3401 2.56188 24.187 3.40895 24.4745 4.48439C24.9967 6.43381 24.9967 10.501 24.9967 10.501C24.9967 10.501 24.9967 14.5683 24.4745 16.5176C24.187 17.593 23.3401 18.4401 22.2646 18.7275C20.3151 19.25 12.4984 19.25 12.4984 19.25C12.4984 19.25 4.68171 19.25 2.73228 18.7275C1.65686 18.4401 0.809784 17.593 0.52236 16.5176C0 14.5683 0 10.501 0 10.501C0 10.501 0 6.43381 0.52236 4.48439C0.809784 3.40895 1.65686 2.56188 2.73228 2.27445C4.68171 1.75208 12.4984 1.75208 12.4984 1.75208C12.4984 1.75208 20.3151 1.75208 22.2646 2.27445ZM9.99873 6.75146V14.2506L16.4929 10.5011L9.99873 6.75146Z'
      fill='currentColor'
    />
  </svg>
)

export default memo(YouTube)
