import { NAMESPACES } from 'constants/locales'

import React, { memo, Suspense, useCallback, useState } from 'react'
import SuspenseFallback from 'components/suspense-fallback'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import useTranslation from 'next-translate/useTranslation'
import LanguageSwitcherAtom from 'components/atoms/language-switcher'
import { trackFooterLinkClick } from 'components/tracking'
import { TFooterLink } from 'models/footer-model.types'

import {
  BottomSection,
  Container,
  Copyright,
  BottomSectionLanguageSwitcher,
  MainSection,
  MainSectionGrid,
  BottomSectionTailLinks,
  WithLove,
  TailColumn,
  StyledColumn,
  StyledMapMarker,
  FooterHeader,
} from './footer.styles'
import TailLinks, { TLinkMouseEvent } from './tail-links'
import { TFooterProps } from './footer.types'
import { LayoutTypes } from './footer.constants'

// TODO: Once language switcher is migrated to TS we can use the type from there
type TLanguageSwitcherOption = { label: string; href: string }

const Footer = (props: TFooterProps) => {
  const {
    palette = Palette.Negative,
    pageLocales = [],
    tailLinks,
    columns = [],
    layoutType = LayoutTypes.Default,
    ...rest
  } = props

  const isCompact = layoutType === LayoutTypes.Compact

  const handleLinkClick = useCallback(
    (link: TFooterLink) => () => {
      trackFooterLinkClick({
        // @ts-expect-error trackFooterLinkClick is not migrated to TS yet!
        key: link.key,
        label: link.label,
        href: link.link,
      })
    },
    []
  )

  const handleCookiePolicySettingsClick = useCallback(
    (event: TLinkMouseEvent) => {
      window?.OneTrust?.ToggleInfoDisplay?.()

      trackFooterLinkClick({
        label: (event?.target as HTMLElement).textContent,
      })
    },
    []
  )

  const handleLanguageSwitcherOptionClick = useCallback(
    ({ option }: { option: TLanguageSwitcherOption }) => {
      trackFooterLinkClick({
        label: option.label,
        href: option.href,
      })
    },
    []
  )

  const { t } = useTranslation(NAMESPACES.COMMON)

  const LanguageSwitcher = (
    <LanguageSwitcherAtom
      pageLocales={pageLocales}
      onLanguageSwitcherOptionClick={handleLanguageSwitcherOptionClick}
    />
  )

  const [expandedAccordion, setExpandedAccordion] = useState<
    string | undefined
  >(undefined)

  const toggleAccordion = useCallback(
    ({ id, label }: { id: string; label: string }) => {
      if (expandedAccordion === id) {
        setExpandedAccordion(undefined)
      } else {
        setExpandedAccordion(id)
        trackFooterLinkClick({ label })
      }
    },
    [expandedAccordion]
  )

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Container palette={palette} {...rest}>
        <FooterHeader>{t('footer.header')}</FooterHeader>
        {!isCompact && (
          <MainSectionGrid>
            <MainSection>
              {columns.map(col => {
                return (
                  <StyledColumn
                    key={col.label}
                    expandedAccordion={expandedAccordion}
                    toggleAccordion={toggleAccordion}
                    onLinkClick={handleLinkClick}
                    {...col}
                  />
                )
              })}
              <TailColumn>
                <TailLinks
                  links={tailLinks}
                  onCookiePolicySettingsClick={handleCookiePolicySettingsClick}
                  onLinkClick={handleLinkClick}
                />
                {LanguageSwitcher}
              </TailColumn>
            </MainSection>
          </MainSectionGrid>
        )}
        <BottomSection isCompact={isCompact} palette={palette}>
          <WithLove>
            <StyledMapMarker aria-hidden />
            {t('footer.location')}
          </WithLove>
          <BottomSectionLanguageSwitcher isCompact={isCompact}>
            {LanguageSwitcher}
          </BottomSectionLanguageSwitcher>
          <BottomSectionTailLinks
            links={tailLinks}
            isCompact={isCompact}
            onCookiePolicySettingsClick={handleCookiePolicySettingsClick}
            onLinkClick={handleLinkClick}
          />
          <Copyright isCompact={isCompact}>© Typeform</Copyright>
        </BottomSection>
      </Container>
    </Suspense>
  )
}

export default memo(Footer)
