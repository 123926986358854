import { fontSize, fontWeight, lineHeight } from 'constants/typography'
import { NeutralColors } from 'constants/colors'
import mediaQueries from 'util/mediaqueries'

import styled, { css } from 'styled-components'
import { SIZES } from 'components/typography/headline'

export const SuperHero = styled.h1`
  color: ${({ inverted }) =>
    inverted ? NeutralColors.White : NeutralColors.Ink};
  font-style: normal;
  font-weight: ${fontWeight.regular};
  letter-spacing: -0.01em;
  font-size: ${fontSize.x3Large};
  line-height: ${lineHeight.xSmall};

  ${mediaQueries.sm`
    font-size: ${fontSize.x4Large};
  `};

  ${mediaQueries.lg`
    font-size: ${fontSize.x5Large};
  `};
`

export const Hero = styled.h2`
  color: ${({ inverted }) =>
    inverted ? NeutralColors.White : NeutralColors.Ink};
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSize.x2Large};
  line-height: ${lineHeight.xSmall};

  ${mediaQueries.sm`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.x3Large};
    letter-spacing: -0.01em;
  `};

  ${mediaQueries.lg`
    font-size: ${fontSize.x4Large};
  `};
`

const baseHeadlineSize0CSS = css`
  font-size: ${fontSize.large};
  line-height: ${lineHeight.small};
`

const baseHeadlineSize1CSS = css`
  font-size: ${fontSize.xLarge};
  line-height: ${lineHeight.small};

  ${mediaQueries.lg`
    font-size: ${fontSize.x2Large};
    line-height: ${lineHeight.xSmall};
  `};
`

const baseHeadlineSize2CSS = css`
  font-size: ${fontSize.xLarge};
  line-height: ${lineHeight.small};

  ${mediaQueries.sm`
    font-size: ${fontSize.x2Large};
    line-height: ${lineHeight.xSmall};
  `};

  ${mediaQueries.lg`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.x3Large};
    letter-spacing: -0.01em;
  `};
`

export const BaseHeadline = styled.h2`
  color: ${({ inverted }) =>
    inverted ? NeutralColors.White : NeutralColors.Ink};
  font-style: normal;
  font-weight: normal;

  ${({ size }) => {
    switch (size) {
      case SIZES.SMALL:
        return baseHeadlineSize0CSS

      case SIZES.LARGE:
        return baseHeadlineSize2CSS

      default:
      case SIZES.MEDIUM:
        return baseHeadlineSize1CSS
    }
  }};
`
