import React from 'react'
import PropTypes from 'prop-types'

import { SuperHero, Hero, BaseHeadline } from './headline.styles'

export const SIZES = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
}

export const DEFAULT_SIZE = SIZES.MEDIUM

export const Headline = ({ children, hero, supa, size, ...rest }) => {
  if (supa && hero) {
    return <SuperHero {...rest}>{children}</SuperHero>
  }
  if (hero) {
    return <Hero {...rest}>{children}</Hero>
  }

  return (
    <BaseHeadline {...rest} size={size}>
      {children}
    </BaseHeadline>
  )
}

Headline.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string,
  hero: PropTypes.bool,
  supa: PropTypes.bool,
  inverted: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
}

Headline.defaultProps = {
  as: undefined,
  hero: false,
  supa: false,
  inverted: false,
  children: null,
  size: DEFAULT_SIZE,
}
