import { memo } from 'react'

const Facebook = props => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.294922 12.9245C0.294922 18.8909 4.65246 23.8518 10.3508 24.8574L10.4176 24.804C10.395 24.7996 10.3723 24.7952 10.3497 24.7906V16.2767H7.33296V12.9247H10.3497V10.2431C10.3497 7.22639 12.2939 5.55041 15.0425 5.55041C15.914 5.55041 16.8525 5.68449 17.724 5.81857V8.90237H16.1821C14.7073 8.90237 14.3721 9.6398 14.3721 10.5783V12.9247H17.59L17.0536 16.2767H14.3721V24.7906C14.35 24.795 14.328 24.7994 14.3059 24.8036L14.3731 24.8574C20.0715 23.8518 24.429 18.8909 24.429 12.9245C24.429 6.28759 18.9988 0.857422 12.362 0.857422C5.72509 0.857422 0.294922 6.28759 0.294922 12.9245Z'
      fill='currentColor'
    />
  </svg>
)

export default memo(Facebook)
