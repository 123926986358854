import React from 'react'

import { Svg } from './styled-components'

export type TVerticalCaretProps = React.HTMLAttributes<HTMLOrSVGElement> & {
  expanded?: boolean
}

const VerticalCaret = (props: TVerticalCaretProps) => {
  return (
    <Svg
      width='9'
      height='5'
      viewBox='0 0 9 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.26342 2.97603L2.24581 0.958417C1.6984 0.410996 0.81085 0.410997 0.263428 0.958418L4.26342 4.95841L8.26343 0.958418C7.71597 0.410997 6.82845 0.410997 6.28103 0.958418L4.26342 2.97603Z'
        fill='currentColor'
      />
    </Svg>
  )
}

export default VerticalCaret
