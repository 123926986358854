import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'

const GridContainer = styled.div`
  display: grid;
  gap: 0 24px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding: 0 32px;

  ${mediaQueries.xs`
    grid-template-columns: repeat(4, minmax(56px, 64px));
  `};

  ${mediaQueries.sm`
    grid-template-columns: repeat(8, minmax(56px, 64px));
  `};

  ${mediaQueries.md`
    grid-template-columns: repeat(12, minmax(56px, 64px));
  `};

  ${mediaQueries.lg`
    grid-gap: 0 32px;
    grid-template-columns: repeat(12, 64px);
  `};
`

export default GridContainer
