import Facebook from 'components/blog/social-icons/social-icon/icons/facebook'
import Instagram from 'components/blog/social-icons/social-icon/icons/instagram'
import LinkedIn from 'components/blog/social-icons/social-icon/icons/linked-in'
import YouTube from 'components/blog/social-icons/social-icon/icons/you-tube'
import Twitter from 'components/blog/social-icons/social-icon/icons/twitter'

const socialIcons = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/typeform/',
    component: Facebook,
  },
  {
    name: 'Twitter',
    href: 'https://www.twitter.com/typeform/',
    component: Twitter,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/typeform/',
    component: Instagram,
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/channel/UC2nRv6J0FsJVePJTLhUDKgg/',
    component: YouTube,
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/typeform-com/',
    component: LinkedIn,
  },
]

export default socialIcons
