import styled from 'styled-components'

export const Svg = styled.svg<{
  expanded?: boolean
}>`
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: transform 0.2s ease-in;
`
