import React, {
  HTMLAttributes,
  MouseEventHandler,
  memo,
  useCallback,
} from 'react'
import { TFooterLink } from 'models/footer-model.types'

import { SocialIconsList } from './styled-components'
import socials from './socials'

type TSocialIconsProps = HTMLAttributes<HTMLUListElement> & {
  onLinkClick?: (link: TFooterLink) => () => void
}

const SocialIcons = (props: TSocialIconsProps) => {
  const { onLinkClick, ...rest } = props

  const handleClick = useCallback(
    (name: string, href: string): MouseEventHandler<HTMLAnchorElement> =>
      () => {
        onLinkClick?.({
          label: name,
          link: href,
        })()
      },
    [onLinkClick]
  )

  return (
    <SocialIconsList {...rest}>
      {socials.map(({ name, href, component: Component }) => (
        <a
          key={name}
          data-gtm-event='nav_element--footer'
          href={href}
          rel='nofollow noreferrer noopener'
          target='_blank'
          onClick={handleClick(name.toLowerCase(), href)}
          aria-label={name}
        >
          <Component />
        </a>
      ))}
    </SocialIconsList>
  )
}

export default memo(SocialIcons)
