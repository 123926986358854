import { memo } from 'react'

const Twitter = props => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.5585 10.5858L23.6652 0H21.5072L13.5998 9.1915L7.28426 0H0L9.55038 13.8992L0 25H2.15812L10.5085 15.2935L17.1782 25H24.4624L14.5579 10.5858H14.5585ZM11.6026 14.0216L10.635 12.6376L2.93572 1.6246H6.25046L12.4639 10.5124L13.4315 11.8965L21.5082 23.4493H18.1934L11.6026 14.0222V14.0216Z'
      fill='currentColor'
    />
  </svg>
)

export default memo(Twitter)
