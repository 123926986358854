import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'

import VerticalCaret from '../vertical-caret'

export const LabelStart = styled.span`
  display: block;
  white-space: pre;
`

export const LabelEnd = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
`

export const AccordionHeading = styled.button`
  /* reset button styles */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;
  font-size: ${fontSize.small};
  text-align: inherit;
  line-height: ${lineHeight.large};

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const StyledVerticalCaret = styled(VerticalCaret)`
  flex-shrink: 0;
`
